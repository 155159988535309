<template>
    <div>
        <div class="flex flex-col flex-1 h-full px-4">
            <table class="min-w-full quext-table">
                <thead>
                    <tr>
                        <th class="pl-2">environment</th>
                        <th>date</th>
                        <th>progress</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="deployment in deployments" :key="deployment.id_website_deployments" class="bg-gray-50 odd:bg-white">
                        <td class="flex py-4 pl-2 whitespace-no-wrap items-center justify-start">
                            <div class="text-sm leading-5 font-medium text-gray-900">
                                {{ deployment.is_production ? 'production' : 'staging' }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-sm">
                                {{ formatDate(deployment.created_at) }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-sm">
                                <div v-if="deployment.created_at !== deployment.updated_at">
                                    complete
                                </div>
                                <Icon v-else name="gridLoader" class="h-4 w-4 text-blue-500" />
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div v-if="deployment.created_at !== deployment.updated_at" class="flex items-center justify-between">
                                <span class="text-sm"> {{ deployment.is_successful ? 'successful' : 'error' }}</span>
                                <div class=" w-4 h-4 mx-1 rounded-full" :class="{'bg-red-300' : !deployment.is_successful, 'bg-green-300' : deployment.is_successful}"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Icon from "@/components/ui/Icon";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";

    export default {
        mixins: [ ModalNavigation ],
        components: { Icon },
        data: () => {
            return {
                website: {},
                polling: null
            }
        },
        computed: {
            ...mapGetters({
                deployments: 'deploys/getDeploys',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadDeploymentsInStore: 'deploys/loadDeploys',
                clearWebsiteInformation: 'website_info/clearWebsiteInformation',
            }),


            formatDate(val) {
                return moment(val).format('M/D/YYYY h:mm a');
            },

            getDeploys() {
                this.$cmsDataProvider.get('deployments', { websiteId: this.website.id }).then((response) => {
                    this.loadDeploymentsInStore(response);
                    this.clearWebsiteInformation();
                },10000)
            },

            pollData() {
                this.polling = setInterval(() => this.getDeploys(), 10000);
            }          
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.getDeploys();
            this.pollData();
        },
        beforeUnmount () {
            clearInterval(this.polling)
        },
    }
</script>

<style scoped>
.quext-table {
    @apply font-frank;
}

.quext-table th {
    @apply font-bold text-sm tracking-wider text-left border-b-2 border-gray-500 pb-1;
}

</style>